// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constant
import { IMAGE_BASE_URL } from '@constants';

/**
 * Returns Image URL with baseUrl
 */
@Pipe({ name: 'imageURL' })
export class imageURLPipe implements PipeTransform {

  /**
   * Transform
   * @param value: string
   */
  transform(value: string): any {
    return IMAGE_BASE_URL + value;
  }
}
