// Angular
import { Pipe, PipeTransform } from "@angular/core";

/**
 * Returns Vehicle Details URL
 */
@Pipe({ name: "VdpUrl" })
export class VDPLURLPipe implements PipeTransform {

  /**
   * Return Vehicle Details Page Link
   * @param {*} route
   * @returns {string}
   * @memberof VDPLURLPipe
   */
  transform(route: any): string {
    return `/${route?.lang}/vs/${route?.search}/${route?.type}/s10/${route?.vc ?? '_'}/${route?.vc2 ?? '_'}/${route?.vcx ?? '_'}/${route?.id}`;
  }
}