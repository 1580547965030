// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Lodash
import { get } from 'lodash';

/**
 * Returns filtered list based on search string
 */
@Pipe({
    name: 'searchFilter',
    pure: false
})
export class SearchFilterPipe implements PipeTransform {

  /**
   * Transform
   * @param items: any[]
   * @param field: string
   * @param value: string
   */
  transform(items: any[], field: string, value: string): any[] {
    if (!items || items.length === 0) return [];
    if (!value || value.length == 0) return items;
    return items.filter(item => get(item, field, '').toLowerCase().includes(value.toLowerCase()));
  }
}
