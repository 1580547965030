// Angular
import { Pipe, PipeTransform, inject } from '@angular/core';
// Transloco
import { TranslocoService } from '@jsverse/transloco';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
  private translocoService = inject(TranslocoService);


  /**
   * Return Yes ||  No
   * @param {*} value
   * @returns {string}
   * @memberof YesNoPipe
   */
  transform(value: any): string {
    if (value === 1) {
      return this.translocoService.translate('label_yes');
    } else if (value === 0) {
      return this.translocoService.translate('label_no');
    } else if (value === null || value === undefined) {
      return this.translocoService.translate('--');
    } else {
      return value.toString();
    }
  }

}
