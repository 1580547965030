// Angular
import { Pipe, PipeTransform, inject } from '@angular/core';
// Lodash
import { get } from 'lodash';
// Service
import { LayoutService } from 'src/app/views/pages/layout/services';

@Pipe({ name: 'companyContactNumber' })
export class CompanyContactNumberPipe implements PipeTransform {
  private readonly layoutService = inject(LayoutService);


  /**
   * Return company contact number based on user country
   * IF both user & company from same country then show domestic number
   * ELSE return international number
   * @param {*} company
   * @returns {*}
   * @memberof CompanyContactNumberPipe
   */
  transform(company: any): any {
    const currentCountry = this.layoutService.getCurrentCountry();
    const companyCountry = get(company, 'visit.country_id') ?? get(company, 'country_id');
    if (companyCountry === currentCountry?.id) {
      return get(company, 'phone.domestic', '--');
    }
    return get(company, 'phone.international', '--');
  }
}
