// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns Vehicle ID
 * @description - The current implementation adds '1' in front of the vehicle id, for example, 1320072.
 * However, dismantlers face difficulty searching for the vehicle with this format.
 * To address this issue, we are now removing the first '1' before displaying the vehicle id.
 */
@Pipe({ name: 'vehicleId' })
export class VehicleIdPipe implements PipeTransform {

  /**
   * Transform
   * @param value: number
   */
  transform(value: number): any {
    if (value) {
      const stringValue = value.toString();
      const result = +stringValue.slice(1);
      return isNaN(result) ? value : result;
    }
    return value;
  }
}
