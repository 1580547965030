// Angular
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, PLATFORM_ID, inject } from '@angular/core';
// Lodash
import { get } from 'lodash';
// Environments
import { environment } from '@environment';

@Component({
    selector: 'app-google-analytics',
    template: ''
})
export class GoogleAnalyticsComponent {
  private readonly platformId = inject<Object>(PLATFORM_ID);
  private document = inject<Document>(DOCUMENT);


  /**
   * Creates an instance of GoogleAnalyticsComponent.
   * @param {Object} platformId
   * @param {Document} document
   * @memberof GoogleAnalyticsComponent
   */
  constructor() {

    // Check Is Google-Analytics Enabled
    const isGoogleAnalyticsEnabled: boolean = get(environment, 'googleAnalytics.isEnabled', false);

    // BROWSER
    if (isGoogleAnalyticsEnabled && isPlatformBrowser(this.platformId)) {
      try {
        this.injectGA4Script();
      } catch (error) {
        console.log('Error while loading Google-Analytic script');
      }
    }
  }

  /**
   * Google Analytics 4
   * @memberof GoogleAnalyticsComponent
   */
  private injectGA4Script(): void {
    try {
      // URL
      const gaTagURL: string = 'https://www.googletagmanager.com/gtag';

      // GA4 Measurement ID
      const measurementId = get(environment, 'googleAnalytics.measurementId');

      // Google tag (gtag.js)
      const scriptGtag = this.document.createElement('script') as HTMLScriptElement;
      scriptGtag.src = `${gaTagURL}/js?id=${measurementId}`;
      scriptGtag.async = true;
      this.document.head.appendChild(scriptGtag);

      const scriptInit = this.document.createElement('script') as HTMLScriptElement;
      const scriptBody = this.document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('consent', 'default', {
          'ad_storage': 'granted',
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'analytics_storage': 'granted'
        });
        gtag('config', '${measurementId}');
    `);

      scriptInit.appendChild(scriptBody);
      this.document.head.appendChild(scriptInit);

    } catch (error) {
      console.log('Error while loading Google-Analytic script');
    }
  }

}