// Angular
import { isPlatformBrowser } from '@angular/common';
import { AfterContentInit, Directive, ElementRef, HostListener, OnInit, PLATFORM_ID, Renderer2, input, inject } from '@angular/core';

/**
 * Directive to lazy load image and add fallack image on image error load
 * @export
 * @class ImageLoaderDirective
 * @implements {OnInit}
 * @implements {AfterContentInit}
 */
@Directive({ selector: 'img[imageLoader]' })
export class ImageLoaderDirective implements OnInit, AfterContentInit {
    private el = inject<ElementRef<HTMLImageElement>>(ElementRef);
    private renderer = inject(Renderer2);
    private platformId = inject<Object>(PLATFORM_ID);


    // INPUTS
    public readonly src = input.required<string>();
    public readonly loaderSrc = input<string>('assets/media/brand-logos/no-logo.webp');
    public readonly errorSrc = input<string>('assets/media/brand-logos/no-logo.webp');
    public readonly lazyLoad = input<boolean>(true);

    // Private
    private alreadyTriedLoading: boolean = false;
    private alreadyTriedError: boolean = false;

    /**
     * On Init
     * @memberof ImageLoaderDirective
     */
    ngOnInit(): void {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());

        if (this.lazyLoad()) {
            this.renderer.setAttribute(this.el.nativeElement, 'loading', 'lazy');
        }
    }

    /**
     * After Content Init
     * @memberof ImageLoaderDirective
     */
    ngAfterContentInit(): void {
        if (this.shouldDisplayLoader()) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.loaderSrc());
        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());
        }
    }

    @HostListener('load')
    public onLoad(): void {
        if (!this.alreadyTriedLoading) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.src());
        }
        this.alreadyTriedLoading = true;
    }

    @HostListener('error')
    public onError(): void {
        if (!this.alreadyTriedError) {
            this.renderer.setAttribute(this.el.nativeElement, 'src', this.errorSrc());
        }
        this.alreadyTriedError = true;
    }

    private shouldDisplayLoader(): boolean {
        return (
            isPlatformBrowser(this.platformId) && !this.el.nativeElement.complete
        );
    }
}