// Angular
import { isPlatformBrowser } from '@angular/common';
import { Directive, HostListener, PLATFORM_ID, input, inject } from '@angular/core';
// Environment
import { environment } from '@environment';

/**
 * Directive to the track Google analytics event i.e select_item
 * @export
 * @class GaSelectItemDirective
 */
@Directive({ selector: '[gaSelectItem]' })
export class GaSelectItemDirective {
    private platformId = inject<Object>(PLATFORM_ID);


    // Input
    readonly gaSelectItem = input<any>();

    /**
     * Click Event
     * @memberof GaSelectItemDirective
     */
    @HostListener('click') onClick() {
        try {
            if (environment.googleAnalytics.isEnabled && isPlatformBrowser(this.platformId)) {
                gtag('event', 'select_item', {
                    items: [this.gaSelectItem()],
                });
            }
        } catch (error) {
            console.log('select-item-ga4', error);
        }
    }
}
