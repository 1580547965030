import { Directive, HostListener, ElementRef, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
    selector: '[appPhoneNumber]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberDirective),
            multi: true
        }
    ]
})
export class PhoneNumberDirective implements ControlValueAccessor {
    private el = inject(ElementRef);

    private onChange: (value: string) => void = () => { };
    private onTouched: () => void = () => { };

    @HostListener('input', ['$event']) onInputChange(event: any) {
        let input = event.target.value;

        // Remove all non-numeric characters except for the '+' if it's at the start
        input = input.replace(/(?!^\+)\D/g, '');

        // Set the formatted input back to the element
        this.el.nativeElement.value = input;

        // Emit the cleaned numeric-only value
        this.onChange(input);
    }

    @HostListener('blur') onBlur() {
        this.onTouched(); // Notify Angular forms that the control has been touched
    }

    // ControlValueAccessor interface methods
    writeValue(value: string): void {
        if (value !== undefined && value !== null) {
            this.el.nativeElement.value = value.replace(/(?!^\+)\D/g, '');
        } else {
            this.el.nativeElement.value = '';
        }
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.el.nativeElement.disabled = isDisabled;
    }
}
