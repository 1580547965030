// Angular
import { Directive, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

/**
 * Directive to the element that we want while rendering on the server 
 * @example Add `*appShellRender` inorder to allow element 
 * @export
 * @class AppShellRenderDirective
 * @implements {OnInit}
 */
@Directive({ selector: '[appShellRender]' })
export class AppShellRenderDirective implements OnInit {
    private platformId = inject<Object>(PLATFORM_ID);
    private templateRef = inject<TemplateRef<any>>(TemplateRef);
    private viewContainer = inject(ViewContainerRef);


    /**
     * On Init
     * @memberof AppShellRenderDirective
     */
    ngOnInit() {
        if (isPlatformServer(this.platformId)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
