// Angular
import { Directive, ElementRef, Renderer2, HostListener, OnDestroy, inject } from '@angular/core';

@Directive({ selector: '[appShakeOnOutsideClick]' })
export class ShakeOnOutsideClickDirective implements OnDestroy {
    private readonly elRef = inject(ElementRef);
    private readonly renderer = inject(Renderer2);


    // Private
    private shakeTimeout: any;

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        const offcanvasElement = this.elRef.nativeElement;
        const overlayContainer = document.querySelector('.cdk-overlay-container'); // Detect overlay container

        // Check if the click was outside the offcanvas
        if (offcanvasElement && !offcanvasElement.contains(event.target)) {

            // Check if the clicked target is inside the overlay container (e.g., dropdown, modal, etc.)
            const clickedElement = event.target as HTMLElement;
            const isInsideOverlay = overlayContainer?.contains(clickedElement);

            // Only trigger the shake if the click is outside both the offcanvas and the overlay
            if (!isInsideOverlay) {
                this.shakeOffcanvas(offcanvasElement);
            }
        }
    }

    /**
     * Shake Offcanvas
     * @private
     * @param {HTMLElement} offcanvasElement
     * @memberof ShakeOnOutsideClickDirective
     */
    private shakeOffcanvas(offcanvasElement: HTMLElement) {
        this.renderer.addClass(offcanvasElement, 'shake-animation');

        // Clear any previous timeout before setting a new one to prevent memory leaks
        if (this.shakeTimeout) {
            clearTimeout(this.shakeTimeout);
        }

        // Remove the animation class after it completes
        this.shakeTimeout = setTimeout(() => {
            this.renderer.removeClass(offcanvasElement, 'shake-animation');
        }, 500); // Match the duration of the CSS animation
    }

    /**
     * On Destroy
     * @memberof ShakeOnOutsideClickDirective
     */
    ngOnDestroy(): void {
        // Clear any pending timeouts on component destruction to prevent memory leaks
        if (this.shakeTimeout) {
            clearTimeout(this.shakeTimeout);
        }
    }
}
