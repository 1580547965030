// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Lodash
import { isArray, split, trim } from 'lodash';

@Pipe({ name: 'partOriginalNo' })
export class PartOriginalNoPipe implements PipeTransform {

  /**
   * Return OEM label & link
   * @param {*} partOriginalNo
   * @param {string} [type]
   * @returns
   * @memberof PartOriginalNoPipe
   */
  transform(partOriginalNo: any, type?: string) {
    switch (type) {
      case 'ALT':
        let references: string[] = [];
        partOriginalNo.forEach((item: any) => {
          references = references.concat(item.reference);
        });
        return references.map(item => {
          return {
            label: item,
            link: encodeURIComponent(trim(item))
          }
        });

      default:
        const input = isArray(partOriginalNo) ? partOriginalNo : split(partOriginalNo, ',');
        return input.map(item => {
          return {
            label: item,
            link: encodeURIComponent(trim(item))
          }
        });
    }
  }
}
