// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constant
import { IMAGE_BASE_URL } from '@constants';
// Lodash
import { get } from 'lodash';

/**
 * Returns URL for PLP Background Path
 */
@Pipe({ name: 'imageBgURL' })
export class imageBgURLPipe implements PipeTransform {

  /**
   * Transform
   * @param value: any
   * @param args: any
   */
  transform(value: any, args?: any): any {
    const imgPath = get(args, 'excludeBaseURL', false) ? value : IMAGE_BASE_URL + value;
    return "url('" + imgPath + "')";
  }
}
