// Angular
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
// Lodash
import { get } from 'lodash';
import { Subscription } from 'rxjs';
// Environments
import { environment } from '@environment';
import { LazyLoadScriptService } from '@core/utils';
// App Version
import packageInfo from 'package.json';

@Component({
    selector: 'app-zendesk-widget',
    template: ''
})
export class ZendeskWidgetComponent implements OnDestroy, AfterViewInit {
  private readonly lazyLoadScriptService = inject(LazyLoadScriptService);
  private readonly platformId = inject<Object>(PLATFORM_ID);


  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   * After HTML View Init
   * @memberof ZendeskWidgetComponent
   */
  ngAfterViewInit(): void {
    const isZendeskEnabled = get(environment, 'zendesk.isZendeskEnabled', false);
    if (isZendeskEnabled && isPlatformBrowser(this.platformId)) {
      try {
        const scriptUrl = get(environment, 'zendesk.scriptUrl', '');
        const lazyLoadZendeskSubscription = this.lazyLoadScriptService.loadScript(scriptUrl, 'ze-snippet').subscribe(() => {
          const appName = packageInfo.name;
          const appVersion = packageInfo.version;
          (window as any).zESettings = {
            errorReporting: false,
            webWidget: {
              contactForm: {
                tags: [`${appName}-v${appVersion}`]
              }
            }
          };
        });
        this.subscriptions.push(lazyLoadZendeskSubscription);
      } catch (error) {
        console.log('Error while loading Zendesk widget script');
      }
    }
  }

  /**
   * OnDestroy
   * @memberof ZendeskWidgetComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}