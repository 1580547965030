// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Lodash
import { toString } from 'lodash';

/**
 * Returns formated month & year
 */
@Pipe({ name: 'monthYearDate' })
export class MonthYearDatePipe implements PipeTransform {

  /**
   * Transform: Returns formated month & year
   * Example: 2023/04 - 2024/05
   * @param from: any
   * @param to: any
   */
  transform(from: any, to: any): any {
    return from ? `${toString(from).substring(0, 4)}/${toString(from).substring(4, 6)} - ${toString(to).substring(0, 4)}/${toString(to).substring(4, 6)}` : 'NA';
  }
}
