// Angular
import { Component, AfterViewInit, PLATFORM_ID, inject } from "@angular/core";
// Utils
import { CommonService } from "../../../utils/common.service";
// RxJs
import { Observable } from "rxjs";
// Transloco
import { TranslocoService, TranslocoDirective } from '@jsverse/transloco';
// Environment
import { environment } from "@environment";
import { isPlatformBrowser, NgClass, NgStyle, AsyncPipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
// Constant
const COOKIES_LS_KEY = `${environment.orgId}-cookie-popup`;
const COOKIE_SETTINGS = `${environment.orgId}-cookie-settings`;

@Component({
    selector: "app-cookie-popup",
    templateUrl: "./cookie-popup.component.html",
    styleUrls: ["./cookie-popup.component.scss"],
    imports: [TranslocoDirective, NgClass, NgStyle, FormsModule, AsyncPipe]
})
export class CookiePopupComponent implements AfterViewInit {
  private readonly translocoService = inject(TranslocoService);
  private readonly cs = inject(CommonService);
  private platformId = inject<Object>(PLATFORM_ID);


  // Public
  hasAccepted: boolean = true;
  languageChange$: Observable<string>;
  isModalOpen: boolean = false;
  siteAnalyticsCookie: boolean = true;
  googleAdsCookie: boolean = true;

  /**
   * After HTML View Init
   * @memberof CookiePopupComponent
   */
  ngAfterViewInit(): void {
    // Get Current live language
    this.languageChange$ = this.translocoService.langChanges$;
    // Get Cookie Consent Value
    const cookieAnswer = this.cs.getFromLocalStorage(COOKIES_LS_KEY);
    this.hasAccepted = null === cookieAnswer ? true : cookieAnswer ? cookieAnswer : false;
  }

  /**
   * Open Cookie Setting Modal
   * @memberof CookiePopupComponent
   */
  openCookieSetting() {
    this.isModalOpen = true;
    this.getCookieSetting();
  }

  /**
   * Open default cookie banner
   * @memberof CookiePopupComponent
   */
  onOpenPopup() {
    this.isModalOpen = false;
    this.hasAccepted = false;
    this.getCookieSetting();
  }

  /**
   * On Accept Site Cookie
   * @memberof CookiePopupComponent
   */
  onAcceptCookie(): void {
    this.hasAccepted = true;
    this.cs.setIntoLocalStorage(COOKIES_LS_KEY, true);
    this.cs.setIntoLocalStorage(COOKIE_SETTINGS, { analtics: true, ads: true });
    if (environment.googleAnalytics.isEnabled && isPlatformBrowser(this.platformId)) this.updateGoogleCookieConsent();
  }

  /**
   * On Save & Close
   * @memberof CookiePopupComponent
   */
  onSaveAndClose(): void {
    this.cs.setIntoLocalStorage(COOKIE_SETTINGS, { analtics: this.siteAnalyticsCookie, ads: this.googleAdsCookie });
    if (environment.googleAnalytics.isEnabled && isPlatformBrowser(this.platformId)) this.updateGoogleCookieConsent();
    this.isModalOpen = false;
    this.hasAccepted = true;
  }

  /**
   * Update Google Cookie Consent
   * @private
   * @returns {void}
   * @memberof CookiePopupComponent
   */
  private updateGoogleCookieConsent(): void {
    try {
      if (!window.dataLayer) return;
      gtag('consent', 'update', {
        'ad_storage': this.googleAdsCookie ? 'granted' : 'denied',
        'ad_user_data': this.googleAdsCookie ? 'granted' : 'denied',
        'ad_personalization': this.googleAdsCookie ? 'granted' : 'denied',
        'analytics_storage': this.siteAnalyticsCookie ? 'granted' : 'denied'
      });
    } catch (error) {
      console.log('set-cookie-constent', error)
    }
  }

  /**
   * Get & Set Cookie Setttings
   * @private
   * @memberof CookiePopupComponent
   */
  private getCookieSetting(): void {
    const cookieSettings = this.cs.getFromLocalStorage(COOKIE_SETTINGS);
    if (cookieSettings) {
      this.siteAnalyticsCookie = cookieSettings?.analtics;
      this.googleAdsCookie = cookieSettings?.ads;
    }
  }
}
