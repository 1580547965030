// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Constant
import { BARND_OTHER_CATEGORIES } from '@constants';

@Pipe({ name: 'brandlogo' })
export class BrandLogoPipe implements PipeTransform {

  /**
   * Transform
   * @param value: any
   */
  transform(value: any): string {
    try {
      const { vc2, vt3 } = value;
      const categories = BARND_OTHER_CATEGORIES[vc2];

      if (categories && categories.length > 0) {
        for (const category of categories) {
          const regex = new RegExp(`\\b${category}\\b`, 'g');
          const result = vt3.replace(regex, '').trim();
          if (result !== vt3) {
            return result;
          }
        }
      }
      return value.vt1;
    } catch (error) {
      return 'no-logo';
    }
  }
}
